import { RegistrationSources } from "../../../app/RegistrationSlice";
import AnalyticsEvent from "../../../types/AnalyticsEvents";
import client from "../../client";

interface SetupAnalyticsResponse {
  status: string;
  error?: any;
}

const postAnalytics = async ({
  type,
  email,
  os,
  platform,
  deviceModel,
  source
}: {
  type: AnalyticsEvent;
  email: string;
  os: string;
  platform: string;
  deviceModel: string;
  source: RegistrationSources
}) => {
  const response = await client.post<SetupAnalyticsResponse>(`/v2/analytics`, {
    type: type,
    data: {
      email: email,
      os: os,
      deviceModel: parseInt(deviceModel),
      platformSelected: platform,
      source: source
    },
  });

  return response;
};

export default postAnalytics;
