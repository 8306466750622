import styled from "@emotion/styled";

const TopRow = styled.div({
  width: "100vw",
  height: "8vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default TopRow;
