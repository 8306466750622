import styled from "@emotion/styled";

const ErrorSeparator = styled.div({
  marginTop: "2vh",
  width: "20vw",
  height: "0px",
  border: "solid 1px red",
  borderRadius: "5px",
});

export default ErrorSeparator;
