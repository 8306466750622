import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
});

const Title = styled.div({
  fontSize: "22px",
  fontWeight: 700,
});

const Message = styled.div({
  fontSize: "18px",
});

const MailtoLink = styled.a({
  marginTop: "10px",
  fontSize: "22px",
  color: "black !important",
  textDecoration: "none",
  ":hover": {
    cursor: "pointer",
    textDecoration: "underline"
  }
})

const ErrorScreen = (props: { message?: string }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>{t("errorScreen.title")}</Title>
      <br></br>
      <Message>{t("errorScreen.message")}</Message>
      <MailtoLink href="mailto:support@pebblebee.com?subject=Web setup issue">support@pebblebee.com</MailtoLink>
    </Container>
  );
};

export default ErrorScreen;
