export const SetupCookies = {
  WarrantyFormCompleted: "warranty_form_completed",
} as const;

class CookieService {
  get(name: string) {
    try {
      const cookieName = `${encodeURIComponent(name)}=`;
      const cookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith(cookieName))
        ?.split("=")[1];
      return cookieValue;
    } catch {
      return "";
    }
  }

  set(
    name: string,
    value: string,
    expires: Date,
    path?: string,
    domain?: string,
    secure?: string,
    httpOnly?: boolean
  ) {
    try {
      let cookieText = `${encodeURIComponent(name)}=${encodeURIComponent(
        value
      )}`;
      if (expires instanceof Date) {
        cookieText += `; expires=${expires.toUTCString()}`;
      }
      if (path) cookieText += `; path=${path}`;
      if (domain) cookieText += `; domain=${domain}`;
      if (secure) cookieText += `; secure`;
      if (httpOnly) cookieText += `; HttpOnly`;

      document.cookie = cookieText;
    } catch {}
  }

  remove(
    name: string,
    path: string,
    domain: string,
    secure: boolean,
    httpOnly?: boolean
  ) {
    try {
      if (!path) path = "/";
      let removeCookie = `${encodeURIComponent(
        name
      )}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
      if (domain) removeCookie += ` domain=${domain};`;
      if (secure) removeCookie += " secure;";
      if (httpOnly) removeCookie += `; HttpOnly`;

      document.cookie = removeCookie;
    } catch {}
  }
}

const cookieService = new CookieService();

export default cookieService;
