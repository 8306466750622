import client from "../../client";

interface RegisterEmailResponse {
  status: string;
  error?: any;
}

const postRegisterEmail = async (email: string) => {
  const response = await client.post<RegisterEmailResponse>(
    `/v2/marketing/register/email`,
    {
      email: email,
    }
  );

  return response;
};

export default postRegisterEmail;
