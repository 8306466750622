import React from "react";
import "./App.css";
import PbSetup from "./components/PbSetup";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  PbDimYellow,
  PbFontBlack,
  PbFontGrayDark,
  PbWhite,
  PbYellow,
} from "./colors";
import GoToMobile from "./components/GoToMobile";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import ErrorScreen from "./components/ErrorScreen/ErrorScreen";
import styled from "@emotion/styled";
import appInfo from "../package.json";

function App() {
  const screenSizeTheme = useTheme();
  const mdScreen = useMediaQuery(screenSizeTheme.breakpoints.up("md"));
  let theme = createTheme({
    palette: {
      primary: {
        main: PbYellow,
        light: PbDimYellow,
        dark: PbDimYellow,
        contrastText: PbFontBlack,
      },
      secondary: {
        main: PbFontBlack,
        light: PbFontGrayDark,
        dark: PbFontBlack,
        contrastText: PbWhite,
      },
    },
    typography: {
      button: {
        textTransform: "none",
        userSelect: "none",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            boxShadow: "none",
            borderRadius: "15px",
            ":hover": {
              boxShadow: "none",
            },
          },
        },
      },
    },
  });
  theme = responsiveFontSizes(theme);

  const VersionMark = styled.div({
    position: "fixed",
    top: "1px",
    right: "5px",
    color: "#C0C0C070",
    fontSize: "12px",
  });

  return (
    <div className="App">
      <ErrorBoundary>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <ThemeProvider theme={theme}>
                  {mdScreen ? <GoToMobile /> : <PbSetup />}
                </ThemeProvider>
              }
            />
            <Route
              path="/:deviceId"
              element={
                <ThemeProvider theme={theme}>
                  {mdScreen ? <GoToMobile /> : <PbSetup />}
                </ThemeProvider>
              }
            />
            <Route path="/error" element={<ErrorScreen />} />
          </Routes>
        </Router>
        <VersionMark>V-{appInfo.version}-B</VersionMark>
      </ErrorBoundary>
    </div>
  );
}

export default App;
