import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import postAnalytics from "../api/requests/userData/postAnalytics";
import postRegisterEmail from "../api/requests/userData/postRegisterEmail";
import i18n from "../i18n/i18n";
import AnalyticsEvent from "../types/AnalyticsEvents";
import DeviceModels from "../types/DeviceModels";
import Os from "../types/Os";
import Plataforms from "../types/Plataforms";

import CostcoSrcImg from "../assets/sources/costco.png";
import BestBuySrcImg from "../assets/sources/bestbuy.png";
import PetSmartSrcImg from "../assets/sources/pet-smart.png";
import AmazonSrcImg from "../assets/sources/amazon.png";
import PebblebeeSrcImg from "../assets/sources/pebblebee.png";
import TrayvaxSrcImg from "../assets/sources/trayvax.png";
import GoogleStore from "../assets/sources/GoogleStore.png";
import PilotFlyingJ from "../assets/sources/PilotFlyingJ.png";

export type WarrantyRegistrationModalStep = "email" | "source";

export const WarrantyFormStatus = {
  none: "",
  started: "started",
  email: "email",
  completed: "completed",
} as const;

const registrationSource = [
  "Google store",
  "Costco",
  "BestBuy",
  "Petsmart",
  "Trayvax",
  "Amazon",
  "Pebblebee",
  "Pilot Flying J",
  "Other",
] as const;

export type RegistrationSources = (typeof registrationSource)[number];

export const isSource = (x: any): x is RegistrationSources =>
  registrationSource.includes(x);

export const sources: { label: RegistrationSources; image: string }[] = [
  { label: "Google store", image: GoogleStore },
  { label: "Costco", image: CostcoSrcImg },
  { label: "BestBuy", image: BestBuySrcImg },
  { label: "Petsmart", image: PetSmartSrcImg },
  { label: "Trayvax", image: TrayvaxSrcImg },
  { label: "Amazon", image: AmazonSrcImg },
  { label: "Pebblebee", image: PebblebeeSrcImg },
  { label: "Pilot Flying J", image: PilotFlyingJ },
];

export interface RegistrationState {
  showWarrantyRegistrationModal: boolean;
  warrantyRegistrationModalStep: WarrantyRegistrationModalStep;
  email?: string;
  source?: RegistrationSources;
  submitingEmail: boolean;
}

const initialState: RegistrationState = {
  showWarrantyRegistrationModal: false,
  warrantyRegistrationModalStep: "email",
  submitingEmail: false,
};

export const registerUser = createAsyncThunk(
  "common/registerUser",
  async ({ email }: { email: string }, thunkApi) => {
    try {
      const response = await postRegisterEmail(email);
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("start.emailModal.errors.apiError")
        );
      }
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("start.emailModal.errors.apiError")
      );
    }
  }
);

export const userAnalytics = createAsyncThunk(
  "common/userAnalytics",
  async (
    {
      type,
      email,
      os,
      platform,
      deviceModel,
      source,
    }: {
      type: AnalyticsEvent;
      email: string;
      os: Os;
      platform: Plataforms;
      deviceModel: DeviceModels;
      source: RegistrationSources | undefined;
    },
    thunkApi
  ) => {
    try {
      const src: RegistrationSources = source ? source : "Other";

      const response = await postAnalytics({
        type,
        email,
        os,
        platform,
        deviceModel,
        source: src,
      });
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("start.emailModal.errors.apiError")
        );
      }
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("start.emailModal.errors.apiError")
      );
    }
  }
);

export const RegistrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setShowWarrantyRegistrationModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showWarrantyRegistrationModal = action.payload;
    },
    setWarrantyRegistrationModalStep: (
      state,
      action: PayloadAction<WarrantyRegistrationModalStep>
    ) => {
      state.warrantyRegistrationModalStep = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setSource: (state, action: PayloadAction<RegistrationSources>) => {
      state.source = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //post register user
      .addCase(registerUser.pending, (state) => {
        state.submitingEmail = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.submitingEmail = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.submitingEmail = false;
      })
      //post register user
      .addCase(userAnalytics.pending, (state) => {})
      .addCase(userAnalytics.fulfilled, (state, action) => {})
      .addCase(userAnalytics.rejected, (state, action) => {});
  },
});

export const {
  setEmail,
  setShowWarrantyRegistrationModal,
  setSource,
  setWarrantyRegistrationModalStep,
} = RegistrationSlice.actions;

export default RegistrationSlice.reducer;
