import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import TextLogo from "../../assets/pebblebe_name_logo.svg";
import Devices from "../../assets/Devices.svg";
import { PbFontBlack, PbFontGrayWatermark, PbYellow } from "../../colors";
import AppContainer from "../ui/AppContainer";
import AppLogo from "../ui/AppLogo";
import StepTitle from "../ui/StepTitle";
import SetupSteps from "../../types/SetupSteps";
import Plataforms, { SwitchAvailablePlataforms } from "../../types/Plataforms";
import { useTranslation } from "react-i18next";
import TopRow from "../ui/TopRow";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import PebblebeeAppGuideGif from "../../assets/videos/instructions/PB_app_iOS_recentered.gif";
import FindMyAppGuideGif from "../../assets/videos/instructions/FindMy_app_medium_resolution.gif";
import FMDGuideGif from "../../assets/videos/instructions/FMD_gif_medium_size_1080 x 1900 px_.gif";
import PebblebeeAppAndroudGuideGif from "../../assets/videos/instructions/Adroid_PB_app_1080x1900.gif";
import StrechSection from "../ui/StrechSection";
import BottomLink from "../ui/BottomLink";
import StepButton from "../ui/StepButton";
import { getDeviceModel } from "../../types/DeviceModels";
import { setSwitchSelectedPlatform } from "../../app/CommonSlice";

const Separator = styled.div({
  marginTop: "2vh",
  width: "20vw",
  height: "0px",
  border: "solid 1px" + PbYellow,
  borderRadius: "5px",
});

const SubtitleText = styled.div({
  marginTop: "2vh",
  maxHeight: "10vh",
  width: "90vw",
  paddingRight: "5vw",
  paddingLeft: "5vw",
  marginBottom: "2vh",
  containerType: "inline-size",
  fontSize: "min(2.5cqh, 5cqi)",
});

const ContentImage = styled.img({
  marginTop: "3vh",
  maxHeight: "30vh",
});

const StyledOrderedList = styled.ol({
  listStyleType: "none",
  counterReset: "listcounter",
  paddingInlineStart: "15px",
  paddingInlineEnd: "15px",
  maxHeight: "50vh",
  overflow: "auto",
  marginTop: "30px",
  zIndex: 10,
  li: {
    position: "relative",
    paddingLeft: "20px",
    display: "flex",
    justifyContent: "flexstart",
    alignItems: "flexstart",
    flexDirection: "column",
    margin: "0px",
    textAlign: "left",
    "&::before": {
      fontWeight: 700,
      content: "counter(listcounter)",
      counterIncrement: "listcounter",
      position: "absolute",
      left: "10px",
      top: "3px",
      width: "23px",
      height: "23px",
      backgroundColor: PbYellow,
      borderRadius: "999px",
      textAlign: "center",
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
  },
});

const MainText = styled.span({
  containerType: "inline-size",
  fontSize: "4cqi",
});

const ListItem = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  fontSize: "1cqi",
  flexDirection: "row",
  margin: "2vh 0",
});

const ContentContainer = styled.div({
  width: "100%",
  textAlign: "left",
  marginLeft: "10px",
});

const IndexContainer = styled.div({
  width: "fit-content",
  aspectRatio: "1/1",
});

const IndexNumber = styled.div({
  containerType: "inline-size",
  fontSize: "min(2.5cqh, 5cqi)",
  backgroundColor: "#f7e138",
  borderRadius: "999px",
  textAlign: "center",
  aspectRatio: "1/1",
  width: "min(2.75cqh, 5cqi)",
});

const GotoLink = styled.a({
  textDecoration: "none",
  color: PbFontBlack,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  containerType: "inline-size",
  fontSize: "min(2.6cqh, 5.5cqi)",
});

const GifContainer = styled.img((props: { expand: number; show: number }) => ({
  height: props.show === 1 ? "0px" : props.expand === 1 ? "80vh" : "35vh",
  width: props.show === 1 ? "0px" : undefined,
  marginTop: "2vh",
  margin: "auto",
  position: props.expand === 1 ? "fixed" : undefined,
  top: props.expand === 1 ? "10vh" : undefined,
  left: props.expand === 1 ? "50%" : undefined,
  transform: props.expand === 1 ? "translateX(-50%)" : undefined,

  zIndex: 1000000,
  ":hover": {
    cursor: "pointer",
  },
  borderRadius: "2vw",
}));

const CoverScreen = styled.span({
  width: "100vw",
  height: "100dvh",
  top: "0vh",
  left: "0vh",
  position: "fixed",
  zIndex: 1000,
  background: "#66666650",
});

const ScrollabelArea = styled.div({
  position: "relative",
  display: "flex",
  width: "100vw",
  flexDirection: "column",
  overflowY: "auto",
  overflowX: "hidden",
  zIndex: 100000,
  "::-webkit-scrollbar": {
    width: "10px",
  },
  "::-webkit-scrollbar:vertical": {
    width: "12px",
  },
  "::-webkit-scrollbar:horizontal": {
    height: "12px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, .5)",
    borderRadius: "10px",
    border: "2px solid #ffffff",
  },
  "::-webkit-scrollbar-track": {
    width: "10px",
    backgroundColor: "#ffffff",
  },
});

const ScrollShadowBottom = styled.div({
  position: "fixed",
  left: 0,
  right: 0,
  height: "30px",
  pointerEvents: "none" /* Allows clicks to pass through */,
  zIndex: 10000000,
  bottom: 0,
  background: "linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))",
  transition: "0.3s",
  borderTopLeftRadius: "45%",
  borderTopRightRadius: "45%",
});

const ScrollContent = styled.div({
  display: "flex",
  width: "100vw",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const SecondaryText = styled.span({
  width: "100%",
  containerType: "inline-size",
  fontSize: "min(2.2cqh, 4cqi)",
  color: PbFontGrayWatermark,
});

const ListFooter = styled.span({
  fontSize: "4cqi",
  color: "#00000085",
});

const ListLink = styled.a({
  color: "#3366ff",
});

interface SetupCompletedProps {
  setStep: (nextStep: SetupSteps) => void;
  previousStep: SetupSteps;
  platform: Plataforms;
  isIos: boolean;
}

const SetupCompleted = (props: SetupCompletedProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const isFmn = useAppSelector((state: RootState) => state.common.isFmnDevice);

  const isMfg = useAppSelector((state: RootState) => state.common.isMfgDevice);

  const isSwitchableDevice = useAppSelector(
    (state: RootState) => state.common.isSwitchableDevice
  );

  const switchSelectedPlatform = useAppSelector(
    (state: RootState) => state.common.switchSelectedPlatform
  );

  const isV1Device = useAppSelector(
    (state: RootState) => state.common.isV1Device
  );

  const email = useAppSelector((state: RootState) => {
    return state.registration.email;
  });

  const selectedSource = useAppSelector(
    (state: RootState) => state.registration.source
  );

  const platform = useAppSelector((state: RootState) => {
    return state.common.platform;
  });

  const model = useAppSelector((state: RootState) => {
    return state.common.deviceModel;
  });

  const [expandGif, setExpandGif] = useState(false);
  const [loadingGif, setLoadingGif] = useState(true);

  useEffect(() => {
    //set default based on OS
    dispatch(setSwitchSelectedPlatform(props.isIos ? "Apple" : "Google"));

    const trimmedEmail = email ? email.trim() : "";

    try {
      if (gtag) {
        gtag("event", "setup_completed_event", {});
        gtag("event", "gather_setup_data", {
          selected_platform: isSwitchableDevice ? "Switchable" : platform,
          model_number: model,
          device_model: getDeviceModel(model),
          subscribed_email: trimmedEmail,
          setup_source: selectedSource,
        });
      }
    } catch {}
  }, []);

  const iosPebblebeeList = () => {
    return (
      <StyledOrderedList>
        <ListItem>
          <IndexContainer>
            <IndexNumber>1</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Pebblebee.steps-ios.first")}</MainText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <IndexContainer>
            <IndexNumber>2</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Pebblebee.steps-ios.second")}</MainText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <IndexContainer>
            <IndexNumber>3</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Pebblebee.steps-ios.third")}</MainText>
          </ContentContainer>
        </ListItem>
      </StyledOrderedList>
    );
  };

  const iosFindmyList = () => {
    return (
      <StyledOrderedList>
        <ListItem>
          <IndexContainer>
            <IndexNumber>1</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Apple.steps-ios.first")}</MainText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <IndexContainer>
            <IndexNumber>2</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Apple.steps-ios.second")}</MainText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <IndexContainer>
            <IndexNumber>3</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Apple.steps-ios.third")}</MainText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <IndexContainer>
            <IndexNumber>4</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Apple.steps-ios.fourth")}</MainText>
          </ContentContainer>
        </ListItem>
      </StyledOrderedList>
    );
  };

  const androidPebblebeeList = () => {
    return (
      <StyledOrderedList>
        <ListItem>
          <IndexContainer>
            <IndexNumber>1</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Pebblebee.steps-android.first")}</MainText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <IndexContainer>
            <IndexNumber>2</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Pebblebee.steps-android.second")}</MainText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <IndexContainer>
            <IndexNumber>3</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Pebblebee.steps-android.third")}</MainText>
          </ContentContainer>
        </ListItem>
      </StyledOrderedList>
    );
  };

  const androidFmdList = () => {
    return (
      <StyledOrderedList>
        <ListItem>
          <IndexContainer>
            <IndexNumber>1</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Google.steps-android.first")}</MainText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <IndexContainer>
            <IndexNumber>2</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Google.steps-android.second")}</MainText>
            <br></br>
            <SecondaryText>
              {t("complete.Google.steps-android.secondSecondary")}
            </SecondaryText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <IndexContainer>
            <IndexNumber>3</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Google.steps-android.third")}</MainText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <IndexContainer>
            <IndexNumber>4</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Google.steps-android.fourth")}</MainText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <IndexContainer>
            <IndexNumber>5</IndexNumber>
          </IndexContainer>
          <ContentContainer>
            <MainText>{t("complete.Google.steps-android.fifth")}</MainText>
          </ContentContainer>
        </ListItem>
        <ListItem>
          <ListFooter>
            {t("complete.Google.steps-android.footer")}
            <ListLink
              target="_blank"
              rel="noreferrer"
              href={t("complete.Google.steps-android.footerLinkHref") as string}
            >
              {t("complete.Google.steps-android.footerLinkText")}
            </ListLink>
          </ListFooter>
        </ListItem>
      </StyledOrderedList>
    );
  };

  const getListSteps = () => {
    if (isSwitchableDevice) {
      if (switchSelectedPlatform === "Apple") {
        return iosFindmyList();
      } else {
        return androidFmdList();
      }
    }

    //PB on ios
    if (props.isIos && props.platform === "Pebblebee") {
      return iosPebblebeeList();
    }

    //Apple find my
    if (props.isIos && props.platform === "Apple") {
      return iosFindmyList();
    }

    //PB on Android
    if (!props.isIos && props.platform === "Pebblebee") {
      return androidPebblebeeList();
    }

    //MFG on android
    if (!props.isIos && isMfg) {
      return androidFmdList();
    }

    //default
    return <ContentImage src={Devices} />;
  };

  useEffect(() => {
    const handleScroll = () => {
      const linkButton = document.getElementById("bottom-link");
      if (!linkButton) return;
      const rect = linkButton.getBoundingClientRect();
      const inView = rect.bottom <= window.innerHeight;
      const scrollShadow = document.getElementById("scroll-shadow");
      if (scrollShadow) {
        scrollShadow.style.display = inView ? "none" : "block";
      }
    };
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
    const handleBeforeUnload = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    const container = document.getElementById("scroll-container");
    if (container) {
      handleScroll(); // Initial check
      window.addEventListener("scroll", (e) => {
        handleScroll();
      });
      window.addEventListener("scrollend", (e) => {
        handleScroll();
      });
      window.addEventListener("touchmove", (e) => {
        handleScroll();
      });
      window.addEventListener("touchend", (e) => {
        handleScroll();
      });
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("scrollend", handleScroll);
        window.removeEventListener("touchmove", handleScroll);
        window.removeEventListener("touchend", handleScroll);
      };
    }
  }, []);

  const gifSource = useMemo(() => {
    const platformSrc =
      props.platform === "Pebblebee"
        ? props.isIos
          ? PebblebeeAppGuideGif
          : PebblebeeAppAndroudGuideGif
        : props.platform === "Apple"
        ? FindMyAppGuideGif
        : FMDGuideGif;

    const switchPlatformSrc =
      switchSelectedPlatform === "Apple" ? FindMyAppGuideGif : FMDGuideGif;

    if (isSwitchableDevice) return switchPlatformSrc;

    return platformSrc;
  }, [isSwitchableDevice, switchSelectedPlatform, props.platform, props.isIos]);

  const setupAnotherLink = useMemo(() => {
    if (isSwitchableDevice) return process.env.REACT_APP_UNIFIED_LINK;

    if (isV1Device) return process.env.REACT_APP_V1_LINK;

    return isFmn
      ? process.env.REACT_APP_FMN_LINK
      : process.env.REACT_APP_MFG_LINK;
  }, [isSwitchableDevice, isV1Device, isFmn]);

  const getTheAppLink = useMemo(() => {
    if (isSwitchableDevice)
      return switchSelectedPlatform === "Apple"
        ? t(`complete.Apple.ios-link`)
        : t(`complete.Google.android-link`);

    return props.isIos
      ? (t(`complete.${props.platform}.ios-link`) as string)
      : (t(`complete.${props.platform}.android-link`) as string);
  }, [isSwitchableDevice, switchSelectedPlatform, props.platform, props.isIos]);

  const buttonText = useMemo(() => {
    if (isSwitchableDevice)
      return switchSelectedPlatform === "Apple"
        ? t(`complete.Apple.link-message`)
        : t(`complete.Google.link-message`);

    return t(`complete.${props.platform}.link-message`);
  }, [isSwitchableDevice, switchSelectedPlatform, props.platform]);

  return (
    <AppContainer>
      <StrechSection
        style={{
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: expandGif ? 10000 : 1000000,
          paddingBottom: "10px",
        }}
      >
        <TopRow>
          <AppLogo src={TextLogo} />
        </TopRow>
        <StepTitle>{t(`complete.${props.platform}.title`)}</StepTitle>
        <Separator />
      </StrechSection>
      <StrechSection
        style={{ display: "flex", width: "100%", justifyContent: "center" }}
      >
        <ScrollabelArea id={"scroll-container"} ref={scrollContainerRef}>
          {expandGif ? null : <ScrollShadowBottom id={"scroll-shadow"} />}
          <ScrollContent>
            {isSwitchableDevice ? (
              <ToggleButtonGroup
                value={switchSelectedPlatform}
                exclusive
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  selection: string | null
                ) => {
                  if (selection !== "Apple" && selection !== "Google") return;
                  setLoadingGif(true);
                  dispatch(
                    setSwitchSelectedPlatform(
                      selection as SwitchAvailablePlataforms
                    )
                  );
                }}
                aria-label="text alignment"
                style={{ marginTop: "10px" }}
              >
                <ToggleButton
                  style={{ width: "25vw" }}
                  value="Apple"
                  aria-label="left aligned"
                >
                  {t("unified.labels.ios")}
                </ToggleButton>
                <ToggleButton
                  style={{ width: "25vw" }}
                  value="Google"
                  aria-label="centered"
                >
                  {t("unified.labels.android")}
                </ToggleButton>
              </ToggleButtonGroup>
            ) : null}
            <SubtitleText>
              {isSwitchableDevice ? t(`unified.stepsSubtitle`) : t(`complete.${props.platform}.subtitle`)}
            </SubtitleText>
            {expandGif ? (
              <CoverScreen
                onClick={() => {
                  setExpandGif(false);
                }}
              />
            ) : null}
            <GifContainer
              onClick={() => {
                setExpandGif(!expandGif);
              }}
              onLoad={() => {
                setLoadingGif(false);
              }}
              src={gifSource}
              alt="guide"
              expand={expandGif ? 1 : 0}
              show={loadingGif ? 1 : 0}
            />
            {loadingGif ? (
              <Skeleton
                sx={{
                  borderRadius: "30px",
                  marginLeft: "32vw",
                  marginRight: "32vw",
                }}
                variant="rectangular"
                width={"35vw"}
                height={"30vh"}
              />
            ) : null}
            {getListSteps()}
            <StepButton variant="contained">
              <GotoLink href={getTheAppLink}>
                <span style={{ fontSize: "1.1rem" }}>{buttonText}</span>
              </GotoLink>
            </StepButton>
            <BottomLink id={"bottom-link"} href={setupAnotherLink}>
              {t("complete.SetupAnother")}
            </BottomLink>
            <br />
          </ScrollContent>
        </ScrollabelArea>
      </StrechSection>
    </AppContainer>
  );
};

export default SetupCompleted;
