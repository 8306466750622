import styled from "@emotion/styled";
import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import Separator from "./Separator";

interface PbAppInfoModalProps {
  title: string;
  body: string;
  button: string;
  open: boolean;
  onClose: () => void;
}

const ModalTitle = styled(Typography)({
  marginTop: "20px",
});

const ModalBody = styled(Typography)({
  textAlign: "center",
  marginBottom: "30px",
  width: "85%",
});

const ModalButton = styled(Button)({
  marginBottom: "10px",
  width: "85%",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontSize: "1.2rem",
});

const PbAppInfoModal = (props: PbAppInfoModalProps) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="compatibility-modal"
      aria-describedby="compatibility-info-modal"
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translateY(-50%) translateX(-50%)",
          width: "90vw",
          background: "#FFF",
          height: "fit-content",
          borderRadius: "25px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1vw",
        }}
      >
        <ModalTitle id="modal-modal-title" variant="h3">
          {props.title}
        </ModalTitle>
        <Separator />
        <ModalBody id="modal-modal-description" sx={{ mt: 2 }}>
          {props.body}
        </ModalBody>
        <ModalButton onClick={props.onClose} variant="contained">
          {props.button}
        </ModalButton>
      </Box>
    </Modal>
  );
};

export default PbAppInfoModal;
