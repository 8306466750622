import styled from "@emotion/styled";

const VerticalStrecher = styled.div({
  minHeight: "100%",
  maxHeight: "100%",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export default VerticalStrecher;
