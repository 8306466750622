import styled from "@emotion/styled";
import { Box, Button, Drawer, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Separator from "../ui/Separator";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import RegisterEmailForm from "./RegistrationEmailForm";
import RegistartionSourceGrid from "./RegistrationSourceGrid";
import { setShowWarrantyRegistrationModal } from "../../app/RegistrationSlice";

const DrawerContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderTopLeftRadius: "2cqi",
  borderTopRightRadius: "2cqi",
  paddingBottom: "4cqi",
});

const DrawerGridContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  margin: "5%",
});

const ModalTitle = styled(Typography)({
  marginTop: "20px",
  fontWeight: 600
});

export const ModalBody = styled(Typography)({
  textAlign: "center",
  marginBottom: "2vh",
  width: "85%",
});

export const ModalButton = styled(Button)({
  width: "85%",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontSize: "1.2rem",
  fontWeight: 600
});

const WarrantyRegistrationDrawer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const showWarrantyRegistrationModal = useAppSelector(
    (state: RootState) => state.registration.showWarrantyRegistrationModal
  );

  const warrantyRegistrationModalStep = useAppSelector(
    (state: RootState) => state.registration.warrantyRegistrationModalStep
  );

  return (
    <Drawer
      anchor={"bottom"}
      open={showWarrantyRegistrationModal}
      onClose={() => {}}
      PaperProps={{
        sx: {
          borderTopLeftRadius: "2cqi",
          borderTopRightRadius: "2cqi",
        },
      }}
    >
      <DrawerContentContainer>
        <ModalTitle id="modal-modal-title" variant="h4">
          {t("start.emailModal.title")}
        </ModalTitle>
        <Separator />
        <DrawerGridContainer>
          {warrantyRegistrationModalStep === "email" ? (
            <RegisterEmailForm />
          ) : (
            <RegistartionSourceGrid />
          )}
        </DrawerGridContainer>
      </DrawerContentContainer>
    </Drawer>
  );
};

export default WarrantyRegistrationDrawer;
