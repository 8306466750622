type DeviceModels = "14" | "16" | "17" | "22" | "23" | "24";

export enum DeviceNames {
  v1Card = "v1-card",
  v1Clip = "v1-clip",
  fmnClip = "clip-fmn",
  fmnCard = "card-fmn",
  fmnTag = "tag-fmn",
  mfgCard = "card-mfg",
  mfgClip = "clip-mfg",
  mfgTag = "tag-mfg",
  switchCard = "card-switch",
  switchClip = "clip-switch",
  switchTag = "tag-switch",
}

export enum DeviceNumbers {
  v1Card = "16",
  v1Clip = "17",
  card = "22",
  clip = "23",
  tag = "24",
}

export const validDeviceName = (deviceId: string) => {
  const deviceNames = Object.values(DeviceNames);

  return deviceNames.includes(deviceId as DeviceNames);
};

export const validDeviceModel = (deviceModel: string) => {
  const deviceNumbers = Object.values(DeviceNumbers);

  return deviceNumbers.includes(deviceModel as DeviceNumbers)
    ? (deviceModel as DeviceModels)
    : null;
};

export const isFmnDevice = (deviceModel: string) => {
  return (
    deviceModel === DeviceNames.fmnCard ||
    deviceModel === DeviceNames.fmnClip ||
    deviceModel === DeviceNames.fmnTag
  );
};

export const isMfgDevice = (deviceModel: string) => {
  return (
    deviceModel === DeviceNames.mfgCard ||
    deviceModel === DeviceNames.mfgClip ||
    deviceModel === DeviceNames.mfgTag
  );
};

export const isV1Device = (deviceModel: string) => {
  return (
    deviceModel === DeviceNames.v1Card || deviceModel === DeviceNames.v1Clip
  );
};

export const isSwitchableDevice = (deviceModel: string) => {
  return (
    deviceModel === DeviceNames.switchTag ||
    deviceModel === DeviceNames.switchClip ||
    deviceModel === DeviceNames.switchCard
  );
};

export const convertDeviceNameToId = (deviceName: DeviceNames) => {
  switch (deviceName) {
    //v1
    case DeviceNames.v1Card:
      return DeviceNumbers.v1Card;
    case DeviceNames.v1Clip:
      return DeviceNumbers.v1Clip;
    //fmn
    case DeviceNames.fmnCard:
      return DeviceNumbers.card;
    case DeviceNames.fmnClip:
      return DeviceNumbers.clip;
    case DeviceNames.fmnTag:
      return DeviceNumbers.tag;
    //mfg
    case DeviceNames.mfgCard:
      return DeviceNumbers.card;
    case DeviceNames.mfgClip:
      return DeviceNumbers.clip;
    case DeviceNames.mfgTag:
      return DeviceNumbers.tag;
    case DeviceNames.switchCard:
      return DeviceNumbers.card;
    case DeviceNames.switchClip:
      return DeviceNumbers.clip;
    case DeviceNames.switchTag:
      return DeviceNumbers.tag;
    default:
      return "0";
  }
};

export const getDeviceModel = (model?: DeviceModels) => {
  if (!model) {
    return "other";
  }

  switch (model) {
    case DeviceNumbers.v1Card:
      return "v1-card";
    case DeviceNumbers.v1Clip:
      return "v1-clip";
    case DeviceNumbers.card:
      return "card";
    case DeviceNumbers.clip:
      return "clip";
    case DeviceNumbers.tag:
      return "tag";
    default:
      return "other";
  }
};

export default DeviceModels;
