import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import TextLogo from "../../assets/pebblebe_name_logo.svg";
import Devices from "../../assets/Devices.svg";
import { PbYellow } from "../../colors";
import AppContainer from "../ui/AppContainer";
import AppLogo from "../ui/AppLogo";
import StepTitle from "../ui/StepTitle";
import SetupSteps from "../../types/SetupSteps";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  setConnectedDeviceModel,
  setIsFmn,
  setIsMfg,
  setIsSwitchable,
  setIsV1,
  setUserSawNewAndOffers,
} from "../../app/CommonSlice";
import { useParams } from "react-router-dom";
import {
  convertDeviceNameToId,
  DeviceNames,
  DeviceNumbers,
  isFmnDevice,
  isMfgDevice,
  isSwitchableDevice,
  isV1Device,
  validDeviceModel,
  validDeviceName,
} from "../../types/DeviceModels";
import ErrorModal from "../ui/ErrorModal";
import localStorageService, {
  SetupLocalStorageKeys,
} from "../../services/LocalStorageService";
import WarrantyRegistrationDrawer from "../StartScreen/WarrantyRegistrationDrawer";
import {
  isSource,
  RegistrationSources,
  setEmail,
  setShowWarrantyRegistrationModal,
  setSource,
  setWarrantyRegistrationModalStep,
  WarrantyFormStatus,
} from "../../app/RegistrationSlice";
import VerticalStrecher from "../ui/VerticalStrech";
import StepButton from "../ui/StepButton";
import StrechSection from "../ui/StrechSection";
import BottomLink from "../ui/BottomLink";
import cookieService, { SetupCookies } from "../../services/CookieService";

const Separator = styled.div({
  marginTop: "2vh",
  width: "20vw",
  height: "0px",
  border: "solid 1px" + PbYellow,
  borderRadius: "5px",
});

const SubtitleText = styled.div({
  marginTop: "3vh",
  maxHeight: "10vh",
  width: "80vw",
  fontSize: "1.5rem",
});

const ContentImage = styled.img({
  marginTop: "7vh",
  maxHeight: "30vh",
});

interface StartStepProps {
  setStep: (nextStep: SetupSteps) => void;
  isIos: boolean;
}

const StartStep = (props: StartStepProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { deviceId } = useParams();

  const userSawNewAndOffers = useAppSelector((state: RootState) => {
    return state.common.userSawNewAndOffers;
  });

  const deviceModel = useAppSelector((state: RootState) => {
    return state.common.deviceModel;
  });

  const isMfg = useAppSelector((state: RootState) => state.common.isMfgDevice)

  const [showLinkErrorModal, setShowLinkErrorModal] = useState(false);

  const [showDeviceTypeSelection, setShowDeviceTypeSelection] = useState(false);

  const UserManualLink = styled.a({
    width: "90vw",
    height: "3vh",
    fontSize: "18px",
    color: "black !important",
    textDecoration: "none",
    ":hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  });

  useEffect(() => {
    const handleNewsAndOffersModal = () => {
      const email = localStorageService.getData(
        SetupLocalStorageKeys.setupEmail
      );
      const source = localStorageService.getData(
        SetupLocalStorageKeys.setupSource
      );

      const formCompleted = cookieService.get(
        SetupCookies.WarrantyFormCompleted
      );

      //first check if completed, if not show and return
      if (!formCompleted || formCompleted !== WarrantyFormStatus.completed) {
        dispatch(setShowWarrantyRegistrationModal(true));
        return;
      }

      //if completed but no email ask email
      if (email === undefined) {
        dispatch(setShowWarrantyRegistrationModal(true));
        return
      } else {
        dispatch(setEmail(email));
      }

      //if completed but no source ask source
      if (source !== undefined && isSource(source)) {
        dispatch(setSource(source as RegistrationSources));
      } else {
        dispatch(setWarrantyRegistrationModalStep("source"));
        dispatch(setShowWarrantyRegistrationModal(true));
      }
    };
    const handleIsV1 = () => {
      setShowDeviceTypeSelection(true);
      dispatch(setIsV1(true));
      handleNewsAndOffersModal();
    };

    const handleIsFmn = () => {
      //the user will input with device type they want to use
      setShowDeviceTypeSelection(true);
      dispatch(setIsFmn(true));
      handleNewsAndOffersModal();
    };

    const handleIsMfg = () => {
      //the user will input with device type they want to use
      setShowDeviceTypeSelection(true);
      dispatch(setIsMfg(true));
      handleNewsAndOffersModal();
    };

    const handleIsSwitch = () => {
      //the user will input with device type they want to use
      setShowDeviceTypeSelection(true);
      dispatch(setIsSwitchable(true));
      handleNewsAndOffersModal();
    };

    const handleDeviceModel = (deviceId: string) => {
      if (validDeviceName(deviceId)) {
        let isMfg = isMfgDevice(deviceId);
        dispatch(setIsMfg(isMfg));
        let isFmn = isFmnDevice(deviceId);
        dispatch(setIsFmn(isFmn));
        let isV1 = isV1Device(deviceId);
        dispatch(setIsV1(isV1));
        let isSwitch = isSwitchableDevice(deviceId)
        dispatch(setIsSwitchable(isSwitch))

        let deviceNumber = convertDeviceNameToId(deviceId as DeviceNames);
        const model = validDeviceModel(deviceNumber);
        if (model) {
          dispatch(setConnectedDeviceModel(model));
        }
        handleNewsAndOffersModal();
      } else {
        //not a valid device id
        setShowLinkErrorModal(true);
      }
    };

    if (deviceId) {
      switch (deviceId) {
        case "v1":
          handleIsV1();
          break;
        case "fmn":
          handleIsFmn();
          break;
        case "mfg":
          handleIsMfg();
          break;
        case "switch":
          handleIsSwitch()
          break;
        default:
          handleDeviceModel(deviceId);
      }
    } else {
      setShowLinkErrorModal(true);
    }
  }, [deviceId, dispatch, props, userSawNewAndOffers]);

  const userManualLink = useMemo(() => {
    if(isMfg)
      return "https://help.pebblebee.com/article/hx8kcsewp0-get-started-with-your-pebblebee-for-android"
    switch (deviceModel) {
      case DeviceNumbers.card:
        return "https://help.pebblebee.com/article/iptqmhpb4m-getting-started-with-your-clard";
      case DeviceNumbers.clip:
        return "https://help.pebblebee.com/article/unzw29opjx-getting-started-with-your-clip";
      case DeviceNumbers.tag:
        return "https://help.pebblebee.com/article/1m51vo5591-getting-started-with-your-tag";
      case DeviceNumbers.v1Card:
        return "https://cdn.shopify.com/s/files/1/0066/1474/5152/files/Pebblebee_Card_Customer_Manual.pdf?v=1676505143";
      case DeviceNumbers.v1Clip:
        return "https://cdn.shopify.com/s/files/1/0066/1474/5152/files/Pebblebee_Clip_Customer_Manual.pdf?v=1676505159";
      default:
        return "https://help.pebblebee.com/category/ikniigc1j2-pebblebee-products";
    }
  }, [deviceModel, isMfg]);

  const isTargetBlank = useMemo(() => {
    return (
      isMfg ||
      deviceModel === DeviceNumbers.v1Card ||
      deviceModel === DeviceNumbers.v1Clip
    );
  }, [deviceModel, isMfg]);

  return (
    <AppContainer>
      <VerticalStrecher>
        <StrechSection>
          <AppLogo src={TextLogo} />
          <StepTitle>{t("start.title")}</StepTitle>
          <Separator />
          <SubtitleText>{t("start.subtitle")}</SubtitleText>
          <ContentImage src={Devices} />
        </StrechSection>
        <StrechSection>
          <StepButton
            variant="contained"
            onClick={() => {
              props.setStep(showDeviceTypeSelection ? "deviceType" : "mode");
            }}
          >
            {t("start.stepButton")}
          </StepButton>
          <BottomLink
            href={userManualLink}
            target={isTargetBlank ? "_blank" : undefined}
          >
            {t("start.userManual")}
          </BottomLink>
        </StrechSection>
      </VerticalStrecher>
      <ErrorModal
        errorType="invalidLink"
        static={false}
        onClose={() => {}}
        open={showLinkErrorModal}
      />
      <WarrantyRegistrationDrawer />
    </AppContainer>
  );
};

export default StartStep;
