import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../assets/Logo.svg";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
  background:
    "linear-gradient(150deg, rgba(255,255,255,1) 0%, rgba(224,224,237,0.7) 35%, rgba(246,238,129,1) 100%)",
});

const Message = styled.div({
  fontSize: "22px",
  fontWeight: 700,
  maxWidth: "80%",
  margin: "60px",
});
const PbLogo = styled.img({
  width: "290px"
});
const GoToMobile = (props: { message?: string }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <PbLogo src={Logo}></PbLogo>
      <Message>{t("goToMobile.message")}</Message>
    </Container>
  );
};

export default GoToMobile;
