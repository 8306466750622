import {
  CircularProgress,
  Link,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  registerUser,
  setEmail,
  setShowWarrantyRegistrationModal,
  setWarrantyRegistrationModalStep,
} from "../../app/RegistrationSlice";
import { RootState } from "../../app/store";
import localStorageService, {
  SetupLocalStorageKeys,
} from "../../services/LocalStorageService";
import { ModalBody, ModalButton } from "./WarrantyRegistrationDrawer";

const SkipButton = styled(Typography)({
  marginTop: "1.5vh",
  marginBottom: "1.5vh",
});

const TermsAndConditionsLink = styled(Link)({
  textDecoration: "none",
  color: "#757ce8",
  paddingTop: "1vh",
});

const RegisterEmailForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [emailError, setEmailError] = useState<
    "" | "emailRequired" | "emailInvalid"
  >("");

  const email = useAppSelector((state: RootState) => {
    return state.registration.email;
  });

  const submitingEmail = useAppSelector((state: RootState) => {
    return state.registration.submitingEmail;
  });

  const isEmail = (email: string) => {
    var patron = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return patron.test(email);
  };

  const submitEmail = async () => {
    if (!email) {
      setEmailError("emailRequired");
      return;
    }

    if (!isEmail(email)) {
      setEmailError("emailInvalid");
      return;
    }

    const trimmedEmail = email.trim();

    const actionResult = await dispatch(registerUser({ email: trimmedEmail }));

    if (actionResult.type.includes("ful")) {
      localStorageService.saveData(
        SetupLocalStorageKeys.setupEmail,
        trimmedEmail
      );
      try {
        if (gtag) {
          gtag("event", "user_nw_subscribed", {
            subscribed_email: trimmedEmail,
          });
        }
      } catch {}
    }

    dispatch(setWarrantyRegistrationModalStep("source"));
  };
  return (
    <>
      <ModalBody id="modal-modal-description" sx={{ mt: 2 }}>
        <span>{t("start.emailModal.message")}</span>
        <br></br>
      </ModalBody>
      <TextField
        style={{
          width: "70vw",
          marginTop: "0.1vh",
        }}
        value={email}
        variant="standard"
        placeholder={t("start.emailModal.placeholder") as string}
        onChange={(e) => {
          setEmailError("");
          dispatch(setEmail(e.target.value));
        }}
        error={emailError !== ""}
        helperText={
          emailError !== ""
            ? (t(`start.emailModal.errors.${emailError}`) as string)
            : ""
        }
      />
      <br></br>
      {submitingEmail ? (
        <CircularProgress />
      ) : (
        <>
          <ModalButton
            onClick={() => {
              submitEmail();
            }}
            variant="contained"
          >
            {t("start.emailModal.submit")}
          </ModalButton>
          <SkipButton
            onClick={() => {
              dispatch(setShowWarrantyRegistrationModal(false));
            }}
            variant="body2"
          >
            {t("start.emailModal.cancel")}
          </SkipButton>
        </>
      )}
      <TermsAndConditionsLink
        color="#blue"
        href="https://help.pebblebee.com/article/e1pty2509q-warranty"
        target={"_blank"}
      >
        {t("start.emailModal.termsAndConditions")}
      </TermsAndConditionsLink>
    </>
  );
};

export default RegisterEmailForm;
