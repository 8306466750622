import styled from "@emotion/styled";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const BackButton = styled(ArrowBackIosNewIcon)({
  position: "absolute",
  left: "3vw",
  height: "20px",
  width: "20px",
  top: "3vh",
  transform: "translateY(50%)",
});

export default BackButton;
