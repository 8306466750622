import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import TranslateIcon from "@mui/icons-material/Translate";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import styled from "@emotion/styled";
import shadows from "@mui/material/styles/shadows";

type ACCEPTED_LANGUAGES = "en" | "es" | "fr" | "gr" | "it" | "nw" | "pr" | "pl";
//ADD ACCEPTED LANGUAGES AND VALUES
const LANGUAGES: { label: string; id: ACCEPTED_LANGUAGES; flag: string }[] = [
  {
    label: "English",
    id: "en",
    flag: ""//"🇬🇧"
  },
  {
    label: "Español",
    id: "es",
    flag: ""//"🇪🇸"
  },
  {
    label: "Français",
    id: "fr",
    flag: ""//"🇫🇷"
  },
  {
    label: "Deutsch",
    id: "gr",
    flag: ""//"🇩🇪"
  },
  {
    label: "Italiano",
    id: "it",
    flag: ""//"🇮🇹"
  },
  {
    label: "Norsk",
    id: "nw",
    flag: ""//"🇳🇴"
  },
  {
    label: "Português",
    id: "pr",
    flag: ""//"🇵🇹"
  },
  {
    label: "Polski",
    id: "pl",
    flag: ""//"🇵🇱"
  },
];

const LanguageManagerContainer = styled.div({
  position: "fixed",
  top: "15px",
  right: "15px",
  zIndex: 1400,
  display: "flex",
  flexDirection: "column",
  justifyItems: "flex-end",
  alignItems: "flex-end",
});

const Container = styled(List)({
  position: "relative",
  zIndex: 10,
  marginTop: "15px",
  background: "white",
  borderRadius: '999px',
  height: "40px",
  width: "40px",
  padding: '0px'
});

const LanguageIconButton = styled(IconButton)({
  position: "relative",
  zIndex: 10,
  boxShadow: shadows[5],
  height: "40px",
  width: "40px",
  background: "white",
});

const StyledTranslateIcon = styled(TranslateIcon)({
  height: "20px",
  width: "20px",
});

const LanguagesList = styled(List)({
  position: "relative",
  zIndex: 10,
  marginTop: "15px",
  boxShadow: shadows[5],
  borderRadius: "10px",
  background: "white",
});

const LanguageListItem = styled(ListItem)({
  zIndex: 2,
  position: "relative",
  paddingRight: "20px",
  paddingLeft: "20px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const LanguageListItemText = styled(ListItemText)({
    marginLeft: "10px",
    justifyContent: "center",
    alignItems: "center",
  });

const LanguageManager = () => {
  const { i18n } = useTranslation();

  const changeLanguageHandler = (lang: ACCEPTED_LANGUAGES) => {
    i18n.changeLanguage(lang);
  };

  const [showList, setShowList] = useState(false);

  return (
    <LanguageManagerContainer>
      <Container>
        <LanguageIconButton
          onClick={() => {
            console.log("click");
            setShowList(!showList);
          }}
        >
          <StyledTranslateIcon />
        </LanguageIconButton>
      </Container>
      {showList ? (
        <LanguagesList>
          {LANGUAGES.map((l, i) => {
            return (
              <>
                <LanguageListItem
                  onClick={() => {
                    changeLanguageHandler(l.id);
                    setShowList(false);
                  }}
                >
                  {l.flag}
                  <LanguageListItemText
                    style={{
                    
                    }}
                    primary={l.label}
                  />
                </LanguageListItem>
                {i === LANGUAGES.length - 1 ? null : (
                  <Divider variant="middle" />
                )}
              </>
            );
          })}
        </LanguagesList>
      ) : null}
    </LanguageManagerContainer>
  );
};

export default LanguageManager;
