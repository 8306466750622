import styled from "@emotion/styled";
import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { PbFontBlack } from "../../colors";
import ErrorTypes from "../../types/ErrorTypes";
import ErrorSeparator from "./ErrorSeparator";

interface ErrorModalProps {
  open: boolean;
  onClose: () => void;
  static: boolean;
  errorType: ErrorTypes;
  buttonAction?: () => void;
}

const ModalTitle = styled(Typography)({
  marginTop: "20px",
  textAlign: "center"
});

const ModalBody = styled(Typography)({
  textAlign: "center",
  marginBottom: "30px",
  width: "85%",
});

const ModalButton = styled(Button)({
  marginBottom: "10px",
  width: "85%",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontSize: "1.2rem",
});

const MailtoLink = styled.a({
  textDecoration: "none",
  color: PbFontBlack,
  fontWeight: 900,
  marginTop: "10px",
  marginBottom: "15px",
});

const ErrorModal = (props: ErrorModalProps) => {
  const { t } = useTranslation();

  const os = useAppSelector((state: RootState) => {
    return state.common.os
  })

  const deviceModel = useAppSelector((state: RootState) => {
    return state.common.deviceModel
  })

  return (
    <Modal
      open={props.open}
      onClose={props.static ? () => {} : props.onClose}
      aria-labelledby="compatibility-modal"
      aria-describedby="compatibility-info-modal"
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translateY(-50%) translateX(-50%)",
          width: "90vw",
          background: "#FFF",
          height: "fit-content",
          borderRadius: "25px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1vw",
        }}
      >
        <ModalTitle id="modal-modal-title" variant="h3">
          {t(`errors.${props.errorType}.title`)}
        </ModalTitle>
        <ErrorSeparator />
        <ModalBody id="modal-modal-description" variant="body1" sx={{ mt: 2 }}>
          {t(`errors.${props.errorType}.message`)}
        </ModalBody>
        {props.buttonAction ? (
          <ModalButton onClick={props.buttonAction} variant="contained">
            {t(`errors.${props.errorType}.button`)}
          </ModalButton>
        ) : null}
        <MailtoLink href={`mailto:support@pebblebee.com?subject=Bluetooth setup update failed --- OS: ${os ? os : "-"} deviceModel: ${deviceModel ? deviceModel : "-"}`}>
          {t("errors.mailTo")}
        </MailtoLink>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
