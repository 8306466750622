import styled from "@emotion/styled";
import { Button } from "@mui/material";

const StepButton = styled(Button)({
  width: "90vw",
  height: "8vh",
  fontSize: "1.2rem",
  margin: "2vh 0",
  zIndex: 10,
  fontWeight: 700
});

export default StepButton;
