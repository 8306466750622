import React from "react";
import { RootState } from "../app/store";
import SetupSteps from "../types/SetupSteps";
import ModeStep from "./steps/ModeSelectionStep";
import SetupCompleted from "./steps/SetupCompleted";
import SplashScreen from "./steps/SplashScreen";
import StartStep from "./steps/StartStep";
import { connect } from "react-redux";
import {
  setShowDeviceDisconnectedModal,
  setOs,
  setDeviceConnected,
} from "../app/CommonSlice";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import Os from "../types/Os";
import SelectDeviceTypeStep from "./steps/SelectDeviceTypeStep";
import LanguageManager from "./LanguageManager/LanguageManage";

interface PbSetupState {
  isIos: boolean;
  step: SetupSteps;
  showSplash: boolean;
  logs: string[];
  showLogs: boolean;
}
interface PbSetupProps {
  showDeviceDisconnectedModal: boolean;
  deviceConnected: boolean;
  setOs: ActionCreatorWithPayload<Os, "common/setOs">;
  setShowDeviceDisconnectedModal: ActionCreatorWithPayload<
    boolean,
    "common/setShowDeviceDisconnectedModal"
  >;
  setDeviceConnected: ActionCreatorWithPayload<
    boolean,
    "common/setDeviceConnected"
  >;
}

class PbSetup extends React.Component<PbSetupProps, PbSetupState> {
  constructor(props: PbSetupProps) {
    super(props);
    this.state = {
      isIos: false,
      step: "start",
      showSplash: true,
      logs: [],
      showLogs: false,
    };
  }

  componentDidMount(): void {
    const ua = navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];

    if (
      /iPad|iPhone|iPod/.test(ua) ||
      iosPlatforms.indexOf(platform) !== -1 ||
      macosPlatforms.indexOf(platform) !== -1 ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      this.setState({
        isIos: true,
      });
      this.props.setOs("iOS");
    } else {
      this.props.setOs("Android");
    }

    setTimeout(() => {
      this.setState({ showSplash: false });
    }, 1000);
  }

  changeStep(nextStep: SetupSteps) {
    this.setState({ step: nextStep });
  }

  getStep() {
    switch (this.state.step) {
      case "start":
        return (
          <StartStep
            setStep={(step: SetupSteps) => {
              this.changeStep(step);
            }}
            isIos={this.state.isIos}
          />
        );
      case "mode":
        return (
          <ModeStep
            setStep={(step: SetupSteps) => {
              this.changeStep(step);
            }}
            isIos={this.state.isIos}
          />
        );
      case "deviceType":
        return (
          <SelectDeviceTypeStep
            setStep={(step: SetupSteps) => {
              this.changeStep(step);
            }}
          />
        );
      case "completeFMI":
        return (
          <SetupCompleted
            setStep={(step: SetupSteps) => {
              this.changeStep(step);
            }}
            isIos={true}
            platform={"Apple"}
            previousStep={"mode"}
          />
        );
      case "completePbApp":
        return (
          <SetupCompleted
            setStep={(step: SetupSteps) => {
              this.changeStep(step);
            }}
            isIos={this.state.isIos}
            platform={"Pebblebee"}
            previousStep={"mode"}
          />
        );
      case "completeFMD":
        return (
          <SetupCompleted
            setStep={(step: SetupSteps) => {
              this.changeStep(step);
            }}
            isIos={this.state.isIos}
            platform={"Google"}
            previousStep={"mode"}
          />
        );
      default:
    }
  }

  render(): React.ReactNode {
    return (
      <div>
        <SplashScreen showSplash={this.state.showSplash} />
        {this.state.showSplash ? null : (
          <>
            {this.state.step === 'start' ? <LanguageManager />: null}
            {this.getStep()}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  showDeviceDisconnectedModal: state.common.showDeviceDisconnectedModal,
  deviceConnected: state.common.deviceConnected,
});

const mapDispatchToProps = {
  setOs,
  setShowDeviceDisconnectedModal,
  setDeviceConnected,
};

export default connect(mapStateToProps, mapDispatchToProps)(PbSetup);
