import styled from "@emotion/styled";
import React from "react";
import { PbYellow } from "../../colors";
import TextLogo from "../../assets/pebblebe_name_logo.svg";

const SplashContainer = styled.div((props: { showSplash: number }) => ({
  position: "fixed",
  top: "0",
  left: "0",
  width: "100vw",
  height: "100vh",
  background: PbYellow,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  opacity: props.showSplash === 1 ? 1 : 0,
  transition: "opacity 1s",
}));

const SplashLogo = styled.img({
  width: "30vw",
  margin: "15px",
});

const SplashScreen = (props: { showSplash: boolean }) => {
  return (
    <SplashContainer showSplash={props.showSplash ? 1 : 0}>
      {/* <SplashLogo src={Logo} /> <SplashLoader />*/}
      <SplashLogo src={TextLogo} />
    </SplashContainer>
  );
};

export default SplashScreen;
