import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { PbFontGray, PbFontGrayWatermark, PbYellow } from "../../colors";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import DeviceModels, { DeviceNumbers, getDeviceModel } from "../../types/DeviceModels";
import { setConnectedDeviceModel } from "../../app/CommonSlice";
import TopRow from "../ui/TopRow";
import BackButton from "../ui/BackButton";
import AppLogo from "../ui/AppLogo";
import SetupSteps from "../../types/SetupSteps";
import TextLogo from "../../assets/pebblebe_name_logo.svg";
import StepTitle from "../ui/StepTitle";
import Separator from "../ui/Separator";
import { useTranslation } from "react-i18next";
import card_black from "../../assets/card_black.svg";
import clip_black from "../../assets/clip_black.svg";
import tag_black from "../../assets/tag_black.svg";
import AppContainer from "../ui/AppContainer";
import StepButton from "../ui/StepButton";
import VerticalStrecher from "../ui/VerticalStrech";
import StrechSection from "../ui/StrechSection";

const CardsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "2vh",
  maxHeight: "50vh",
  position: "relative",
});

const DeviceCard = styled.div((props: { isSelected: number }) => ({
  width: "75vw",
  height: "22vh",
  marginTop: "2vh",
  border:
    props.isSelected === 1
      ? "solid 3px" + PbYellow
      : "solid 2px" + PbFontGrayWatermark,
  borderRadius: "20px",
  transition: "0.2s",
  background: "#FFFFFF",
  zIndex: props.isSelected ? 2 : 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "hidden",
  justifyContent: "center",
}));

const CardLogo = styled.img({
  height: "7vh",
  margin: "0.5vh",
});

const CardTitle = styled(Typography)({
  fontSize: "1.2rem",
  color: PbFontGray,
  fontWeight: 700,
  margin: "0.5vh 0",
});

const InfoText = styled(Typography)({
  marginTop: "1vh",
  maxHeight: "10vh",
  width: "70vw",
  fontSize: "1rem",
  color: PbFontGrayWatermark,
});

interface SelectDeviceTypeStepProps {
  setStep: (nextStep: SetupSteps) => void;
}

const SelectDeviceTypeStep = (props: SelectDeviceTypeStepProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const model = useAppSelector((state: RootState) => {
    return state.common.deviceModel;
  });

  const isV1Device = useAppSelector(
    (state: RootState) => state.common.isV1Device
  );

  useEffect(() => {
    dispatch(
      setConnectedDeviceModel(
        isV1Device ? DeviceNumbers.v1Card : DeviceNumbers.card
      )
    );
  }, [dispatch, isV1Device]);

  const selectDeviceType = (deviceType: DeviceModels) => {
    dispatch(setConnectedDeviceModel(deviceType));
  };

  return (
    <AppContainer>
      <VerticalStrecher>
        <StrechSection>
          <TopRow>
            <BackButton
              onClick={() => {
                props.setStep("start");
              }}
            />
            <AppLogo src={TextLogo} />
          </TopRow>
          <StepTitle variant="h2">{t("deviceSelect.title")}</StepTitle>
          <Separator />
          <InfoText variant="body2">{t("deviceSelect.subtitle")}</InfoText>
          <CardsContainer>
            <DeviceCard
              isSelected={
                model === DeviceNumbers.card || model === DeviceNumbers.v1Card
                  ? 1
                  : 0
              }
              onClick={() => {
                selectDeviceType(
                  isV1Device ? DeviceNumbers.v1Card : DeviceNumbers.card
                );
              }}
            >
              <CardLogo src={card_black}></CardLogo>
              <CardTitle>{t("deviceSelect.card")}</CardTitle>
            </DeviceCard>
            <DeviceCard
              isSelected={
                model === DeviceNumbers.clip || model === DeviceNumbers.v1Clip
                  ? 1
                  : 0
              }
              onClick={() => {
                selectDeviceType(
                  isV1Device ? DeviceNumbers.v1Clip : DeviceNumbers.clip
                );
              }}
            >
              <CardLogo src={clip_black}></CardLogo>
              <CardTitle>{t("deviceSelect.clip")}</CardTitle>
            </DeviceCard>
            {!isV1Device && (
              <DeviceCard
                isSelected={model === DeviceNumbers.tag ? 1 : 0}
                onClick={() => {
                  selectDeviceType(DeviceNumbers.tag);
                }}
              >
                <CardLogo src={tag_black}></CardLogo>
                <CardTitle>{t("deviceSelect.tag")}</CardTitle>
              </DeviceCard>
            )}
          </CardsContainer>
        </StrechSection>
        <StrechSection>
          <StepButton
            variant="contained"
            onClick={() => {
              try {
                if (gtag) {
                  gtag("event", "setup_device_selected", {
                    model_number: model,
                    device_model: getDeviceModel(model),
                  });
                }
              } catch {}
              props.setStep("mode");
            }}
          >
            {t("mode.stepButton")}
          </StepButton>
        </StrechSection>
      </VerticalStrecher>
    </AppContainer>
  );
};

export default SelectDeviceTypeStep;
