import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  RegistrationSources,
  setShowWarrantyRegistrationModal,
  setSource,
  sources,
  WarrantyFormStatus,
} from "../../app/RegistrationSlice";
import { RootState } from "../../app/store";
import cookieService, { SetupCookies } from "../../services/CookieService";
import localStorageService, {
  SetupLocalStorageKeys,
} from "../../services/LocalStorageService";
import { ModalBody, ModalButton } from "./WarrantyRegistrationDrawer";

const SourceImage = styled.img({
  width: "auto",
  maxWidth: "90%",
});

const GridItem = styled(Grid)({
  padding: "8px",
});

const SourcePill = styled(Box)((props: { seleted: number }) => ({
  borderRadius: "2.5cqi",
  border: props.seleted === 1 ? "solid 2px #22222290" : "solid 1px #66666660",
  width: "90%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: props.seleted === 1 ? "3px" : "4px",
  height: "4vh",
}));

const RegistartionSourceGrid = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedSource = useAppSelector(
    (state: RootState) => state.registration.source
  );

  const [sourceError, setSourceError] = useState("");

  const submitSource = () => {
    if (!selectedSource) {
      setSourceError("sourceRequired");
      return;
    }

    try {
      if (gtag) {
        gtag("event", "setup_source_event", { setup_source: selectedSource });
      }
    } catch {}

    localStorageService.saveData(
      SetupLocalStorageKeys.setupSource,
      selectedSource
    );

    const oneDayFromNow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

    cookieService.set(
      SetupCookies.WarrantyFormCompleted,
      WarrantyFormStatus.completed,
      oneDayFromNow
    );
    dispatch(setShowWarrantyRegistrationModal(false));
  };

  const setSelectedSource = (source: RegistrationSources) => {
    dispatch(setSource(source));
  };

  return (
    <>
      <ModalBody id="modal-modal-description" sx={{ mt: 2 }}>
        {t("start.sourcesModal.message")}
      </ModalBody>
      <Grid
        style={{ padding: "0px" }}
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        {sources.map((source) => {
          return (
            <GridItem item xs={4}>
              <SourcePill
                onClick={() => {
                  setSelectedSource(source.label);
                }}
                seleted={selectedSource === source.label ? 1 : 0}
              >
                <SourceImage src={source.image} />
              </SourcePill>
            </GridItem>
          );
        })}
        <GridItem item xs={4}>
          <SourcePill
            onClick={() => {
              setSelectedSource("Other");
            }}
            seleted={selectedSource === "Other" ? 1 : 0}
          >
            {t("start.sourcesModal.otherOptionLabel")}
          </SourcePill>
        </GridItem>
      </Grid>
      {sourceError !== "" ? t(`start.sourcesModal.errors.${sourceError}`) : ""}
      <br></br>
      <ModalButton variant="contained" onClick={submitSource}>
        {t("start.sourcesModal.submit")}
      </ModalButton>
    </>
  );
};

export default RegistartionSourceGrid;
