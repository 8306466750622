import styled from "@emotion/styled";
import { PbYellow } from "../../colors";

const Separator = styled.div({
  marginTop: "2vh",
  width: "20vw",
  height: "0px",
  border: "solid 1px" + PbYellow,
  borderRadius: "5px",
});

export default Separator;
