import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const StepTitle = styled(Typography)({
  marginTop: "2vh",
  maxHeight: "8vh",
  containerType: "inline-size",
  width: "90vw",
  fontSize: "6.5cqi",
  fontWeight: 700,
});

export default StepTitle;
