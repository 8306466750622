import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

//resources
import enTranslation from "./translations/en.json";
import esTranslation from "./translations/es.json";
import frTranslation from "./translations/fr.json";
import grTranslation from "./translations/gr.json";
import itTranslation from "./translations/it.json";
import nwTranslation from "./translations/nw.json";
import plTranslation from "./translations/pl.json";
import prTranslation from "./translations/pr.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  gr: {
    translation: grTranslation,
  },
  it: {
    translation: itTranslation,
  },
  nw: {
    translation: nwTranslation,
  },
  pl: {
    translation: plTranslation,
  },
  pr: {
    translation: prTranslation,
  },
};

i18n
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: resources,
  });

export default i18n;
