export enum SetupLocalStorageKeys {
    setupEmail = "stp-email",
    setupSource = "stp-source"
}

class LocalStorageService {
  saveData(key: string, data: any) {
    window.localStorage.setItem(key, JSON.stringify(data));
  }

  getData(key: string) {
    try {
      const data = window.localStorage.getItem(key);
      if (data) {
        return JSON.parse(data);
      }
    } catch {
      return undefined
    }
  }
}

const localStorageService = new LocalStorageService();

export default localStorageService;
