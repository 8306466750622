import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import {
  PbFontBlack,
  PbFontGray,
  PbFontGrayWatermark,
  PbGreen,
  PbYellow,
} from "../../colors";
import TextLogo from "../../assets/pebblebe_name_logo.svg";
import Pebblebee_app from "../../assets/CardArtboard 1.svg";
import FindMy_app from "../../assets/FindMy_app.svg";
import Unified_image from "../../assets/unified-tracking.svg";
import GlgFindMy_app from "../../assets/FMD_logo_192px_clr.svg";

import AppContainer from "../ui/AppContainer";
import StepButton from "../ui/StepButton";
import AppLogo from "../ui/AppLogo";
import StepTitle from "../ui/StepTitle";
import { useTranslation } from "react-i18next";
import Plataforms from "../../types/Plataforms";
import SetupSteps from "../../types/SetupSteps";
import TopRow from "../ui/TopRow";
import BackButton from "../ui/BackButton";
import PbAppInfoModal from "../ui/PbAppInfoModal";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setPlatform } from "../../app/CommonSlice";
import { RootState } from "../../app/store";
import { userAnalytics } from "../../app/RegistrationSlice";
import VerticalStrecher from "../ui/VerticalStrech";
import StrechSection from "../ui/StrechSection";

const Separator = styled.div({
  marginTop: "2vh",
  width: "20vw",
  height: "0px",
  border: "solid 1px" + PbYellow,
  borderRadius: "5px",
});

const InfoText = styled(Typography)({
  marginTop: "1vh",
  maxHeight: "10vh",
  width: "92vw",
  fontSize: "4.1cqi",
  color: PbFontGrayWatermark,
  containerType: "inline-size",
});

const CardsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "4vh",
  maxHeight: "51vh",
  position: "relative",
});

const CardTitle = styled(Typography)((props: { isSelected: number }) => ({
  fontSize: "min(3cqh, 6.5cqi)",
  color: props.isSelected === 1 ? PbFontBlack : PbFontGray,
  fontWeight: 700,
  marginBottom: "1.5vh",
  marginTop: "1.5vh",
}));

const CardText = styled(Typography)((props: { isSelected: number }) => ({
  fontSize: "min(2.1cqh, 4.1cqi)",
  lineHeight: 1.2,
  wordWrap: "break-word",
  textAlign: "center",
  color: props.isSelected === 1 ? PbFontBlack : PbFontGray,
  marginBottom: "auto",
  marginTop: props.isSelected === 1 ? "15px" : "0px",
  width: "90%",
}));

const CardLogo = styled.img({
  height: "10vh",
  margin: "auto",
});

const PlataformCard = styled.div(
  (props: { isSelected: number; isPb: number }) => ({
    position: "absolute",
    top: props.isSelected === 1 ? "0" : "32vh",
    width: "90vw",
    //height: props.isSelected === 1 ? "28vh" : "16vh",
    border:
      props.isSelected === 1
        ? props.isPb
          ? "solid 2px" + PbYellow
          : "solid 2px" + PbGreen
        : "solid 2px" + PbFontGrayWatermark,
    borderRadius: "20px",
    transition: "0.5s",
    background: "#FFFFFF",
    zIndex: props.isSelected ? 2 : 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    containerType: "inline-size",
  })
);

const UnifiedContent = styled.div({
  marginTop: "2vh",
  height: "auto",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
});

const UnifiedLogo = styled.img({
  width: "90vw",
});

const UnifiedNote = styled.div({
  color: PbFontGrayWatermark,
  fontSize: "2.8cqi",
  marginTop: "2vh",
  textAlign: "left",
  marginRight: "3vh",
  marginLeft: "3vh",
});

interface ModeStepProps {
  isIos: boolean;
  setStep: (nextStep: SetupSteps) => void;
}

const ModeStep = (props: ModeStepProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const email = useAppSelector((state: RootState) => {
    return state.registration.email;
  });

  const os = useAppSelector((state: RootState) => {
    return state.common.os;
  });

  const deviceModel = useAppSelector((state: RootState) => {
    return state.common.deviceModel;
  });

  const platform = useAppSelector((state: RootState) => {
    return state.common.platform;
  });

  const isMfgDevice = useAppSelector((state: RootState) => {
    return state.common.isMfgDevice;
  });

  const isSwitchableDevice = useAppSelector((state: RootState) => {
    return state.common.isSwitchableDevice;
  });

  const showDeviceDisconnectedModal = useAppSelector((state: RootState) => {
    return state.common.showDeviceDisconnectedModal;
  });

  const selectedSource = useAppSelector(
    (state: RootState) => state.registration.source
  );

  const [showCompatibilityModal, setShowCompatibilityModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (props.isIos) {
      if (isMfgDevice) {
        dispatch(setPlatform("Pebblebee"));
      } else {
        dispatch(setPlatform("Apple"));
      }
    } else {
      if (isMfgDevice) {
        dispatch(setPlatform("Google"));
      } else {
        dispatch(setPlatform("Pebblebee"));
      }
    }
  }, [dispatch, isMfgDevice, props.isIos]);

  const getCardLogo = (type: Plataforms) => {
    switch (type) {
      case "Pebblebee":
        return Pebblebee_app;
      case "Apple":
        return FindMy_app;
      case "Google":
        return GlgFindMy_app;
    }
  };

  const getCard = (type: Plataforms) => {
    return (
      <PlataformCard
        onClick={() => {
          dispatch(setPlatform(type));
        }}
        isPb={type === "Pebblebee" ? 1 : 0}
        isSelected={platform === type ? 1 : 0}
      >
        <CardTitle isSelected={platform === type ? 1 : 0}>
          {t(`mode.cards.${type}.title`)}
        </CardTitle>
        {platform === type ? <CardLogo src={getCardLogo(type)} /> : null}
        <CardText isSelected={platform === type ? 1 : 0}>
          {t(`mode.cards.${type}.body`)}
        </CardText>
      </PlataformCard>
    );
  };

  const sendAnalyticsData = async () => {
    try {
      console.log("Device analytics: ", {
        type: "OTA_PLATFORM_SELECTION",
        email: email,
        os: os,
        platform: platform,
        deviceModel: deviceModel,
      });

      if (email && os && platform && deviceModel) {
        //SEND ANALITICS
        await dispatch(
          userAnalytics({
            type: "OTA_PLATFORM_SELECTION",
            email: email,
            os: os,
            platform: platform,
            deviceModel: deviceModel,
            source: selectedSource,
          })
        );
      }
    } catch (e) {}
  };

  const onContinueClick = async () => {
    dispatch(setPlatform(platform));
    switch (platform) {
      case "Pebblebee":
        await sendAnalyticsData();
        props.setStep("completePbApp");
        break;
      case "Apple":
        await sendAnalyticsData();
        props.setStep("completeFMI");
        break;
      case "Google":
        await sendAnalyticsData();
        props.setStep("completeFMD");
        break;
    }
  };

  const underlineLocalizedText = (text: string) => {
    const parts = text.split("<u>");
    return (
      <span>
        {parts.map((part, index) => {
          if (index % 2 === 0) return part;
          return <u>{part}</u>
        })}
      </span>
    );
  };

  const splitLocalizedText = (text: string) => {
    const parts = text.split("<br>");
    return (
      <span>
        {parts.map((part) => {
          return <>{underlineLocalizedText(part)}<br /></>
        })}
      </span>
    );
  };

  return (
    <AppContainer>
      <VerticalStrecher>
        <StrechSection style={{width: '100%'}}>
          <TopRow>
            <BackButton
              onClick={() => {
                props.setStep("start");
              }}
            />
            <AppLogo src={TextLogo} />
          </TopRow>
          <StepTitle variant="h2">
            {isSwitchableDevice ? t("unified.title") : t("mode.title")}
          </StepTitle>
          <Separator />
          <InfoText variant="body2">
            {isSwitchableDevice
              ? splitLocalizedText(t("unified.subtitle") as string)
              : props.isIos
              ? isMfgDevice
                ? t("mode.subtitle.iosMFG")
                : t("mode.subtitle.ios")
              : isMfgDevice
              ? t("mode.subtitle.fmd")
              : t("mode.subtitle.android")}
          </InfoText>
          <CardsContainer
            style={{ height: isSwitchableDevice ? "auto" : undefined }}
          >
            {isSwitchableDevice ? (
              <UnifiedContent>
                <UnifiedLogo src={Unified_image} />
              </UnifiedContent>
            ) : props.isIos ? (
              isMfgDevice ? (
                getCard("Pebblebee")
              ) : (
                <>
                  {getCard("Apple")}
                  {getCard("Pebblebee")}
                </>
              )
            ) : isMfgDevice ? (
              getCard("Google")
            ) : (
              getCard("Pebblebee")
            )}
          </CardsContainer>
        </StrechSection>
        <StrechSection>
          {isSwitchableDevice ? (
            <UnifiedNote>{t("unified.watermark")}</UnifiedNote>
          ) : null}
          <StepButton
            variant="contained"
            disabled={showDeviceDisconnectedModal}
            onClick={() => {
              try {
                if (gtag) {
                  gtag("event", "setup_platform_event", {
                    selected_platform: platform,
                  });
                }
              } catch {}
              onContinueClick();
            }}
          >
            {t("mode.stepButton")}
          </StepButton>
        </StrechSection>
        <PbAppInfoModal
          open={showCompatibilityModal}
          onClose={() => {
            setShowCompatibilityModal(false);
          }}
          title={t("mode.compatibilityModal.title") as string}
          body={t("mode.compatibilityModal.body") as string}
          button={t("mode.compatibilityModal.button") as string}
        />
      </VerticalStrecher>
    </AppContainer>
  );
};

export default ModeStep;
