import styled from "@emotion/styled"

const AppContainer = styled.div({
  height: `99dvh`,
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
});

export default AppContainer;
