import styled from "@emotion/styled";

const BottomLink = styled.a({
  width: "90vw",
  height: "3vh",
  fontSize: "18px",
  color: "black !important",
  textDecoration: "none",
  margin: "1vh 0",
  zIndex: "10",
  ":hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
});

export default BottomLink;