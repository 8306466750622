import axios from "axios";

const client = axios.create({
    baseURL: process.env.REACT_APP_CORE_API_HOST,
    headers: {
        "Content-Type": "application/json",
        "PB-APP-ID": process.env.REACT_APP_PB_APP_ID
    }
})

export default client;