import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DeviceModels from "../types/DeviceModels";
import Os from "../types/Os";
import Plataforms, { SwitchAvailablePlataforms } from "../types/Plataforms";
import SetupSteps from "../types/SetupSteps";

export interface CommonState {
  step: SetupSteps;
  os?: Os;
  platform: Plataforms;
  deviceModel?: DeviceModels;
  isFmnDevice: boolean;
  isMfgDevice: boolean;
  isV1Device: boolean;
  isSwitchableDevice: boolean;
  switchSelectedPlatform: SwitchAvailablePlataforms;
  deviceBattery: number;
  showDeviceDisconnectedModal: boolean;
  deviceConnected: boolean;
  userSawNewAndOffers: boolean;
}

const initialState: CommonState = {
  step: "start",
  platform: "Pebblebee",
  isFmnDevice: false,
  isMfgDevice: false,
  isV1Device: false,
  isSwitchableDevice: false,
  switchSelectedPlatform: 'Google',
  deviceBattery: 0,
  showDeviceDisconnectedModal: false,
  deviceConnected: false,
  userSawNewAndOffers: false,
};

export const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSteps: (state, action: PayloadAction<SetupSteps>) => {
      state.step = action.payload;
    },
    setOs: (state, action: PayloadAction<Os>) => {
      state.os = action.payload;
    },
    setPlatform: (state, action: PayloadAction<Plataforms>) => {
      state.platform = action.payload;
    },
    setConnectedDeviceModel: (
      state,
      action: PayloadAction<DeviceModels | undefined>
    ) => {
      state.deviceModel = action.payload;
    },
    setIsFmn: (state, action: PayloadAction<boolean>) => {
      state.isFmnDevice = action.payload;
    },
    setIsMfg: (state, action: PayloadAction<boolean>) => {
      state.isMfgDevice = action.payload;
    },
    setIsV1: (state, action: PayloadAction<boolean>) => {
      state.isV1Device = action.payload;
    },
    setIsSwitchable: (state, action: PayloadAction<boolean>) => {
      state.isSwitchableDevice = action.payload;
    },
    setSwitchSelectedPlatform: (state, action: PayloadAction<SwitchAvailablePlataforms>) => {
      state.switchSelectedPlatform = action.payload;
    },
    setDeviceBattery: (state, action: PayloadAction<number>) => {
      state.deviceBattery = action.payload;
    },
    setShowDeviceDisconnectedModal: (state, action: PayloadAction<boolean>) => {
      state.showDeviceDisconnectedModal = action.payload;
    },
    setDeviceConnected: (state, action: PayloadAction<boolean>) => {
      state.deviceConnected = action.payload;
    },
    setUserSawNewAndOffers: (state, action: PayloadAction<boolean>) => {
      state.userSawNewAndOffers = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setConnectedDeviceModel,
  setOs,
  setPlatform,
  setSteps,
  setIsFmn,
  setIsMfg,
  setDeviceBattery,
  setShowDeviceDisconnectedModal,
  setDeviceConnected,
  setUserSawNewAndOffers,
  setIsV1,
  setIsSwitchable,
  setSwitchSelectedPlatform
} = CommonSlice.actions;

export default CommonSlice.reducer;
