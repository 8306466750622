import styled from "@emotion/styled";

const AppLogo = styled.img({
  marginTop: "3vh",
  width: "36vw",
  height: "auto",
  maxHeight: "5vh",
});

export default AppLogo;
